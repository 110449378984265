<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <form role="form">
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Data rozpoczęcia</label>
          <div class="col-sm-10">
            <date-picker
              class="NeoDataPicker"
              v-model="training_date.date_start"
              :config="optionsDate"
              name="date_start"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Data zakończenia</label>
          <div class="col-sm-10">
            <date-picker
              class="NeoDataPicker"
              v-model="training_date.date_end"
              :config="optionsDate"
              name="date_start"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Miasto</label>
          <div class="col-sm-10">
            <select
              class="form-control"
              name="city_id"
              v-validate="'required'"
              v-model="training_date.city_id"
              autocomplete="off"
            >
              <option v-for="item in cities" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Szkolenie</label>
          <div class="col-sm-10">
            <select
              class="form-control"
              name="training_id"
              v-validate="'required'"
              v-model="training_date.training_id"
              autocomplete="off"
            >
              <option v-for="item in trainings" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-12">
          <div @click="saveChange" class="btn btn-primary">
            Zapisz
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainingDatesForm",
  data() {
    return {
      training_date: {
        date_start: "",
        date_end: "",
        city_id: "",
        training_id: ""
      },
      cities: null,
      trainings: null
    };
  },
  created() {
    Promise.all([this.getData(), this.getCities(), this.getTrainings()])
      .then(() => {
        this.$emit("loading", false);
      })
      .catch(() => {
        this.$emit("loading", false);
      });
  },
  methods: {
    async getCities() {
      this.$emit("loading", true);
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/training-cities`;
      await this.$http
        .get(url)
        .then(resp => {
          this.cities = resp.data;
        })
        .catch(err => {
          this.$toastr.error(err);
        });
    },
    async getTrainings() {
      this.$emit("loading", true);
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/training-training`;
      await this.$http
        .get(url)
        .then(resp => {
          this.trainings = resp.data;
        })
        .catch(err => {
          this.$toastr.error(err);
        });
    },
    async getData() {
      if (this.$route.params.id != undefined) {
        this.$emit("loading", true);
        let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/training-dates/${this.$route.params.id}`;
        await this.$http
          .get(url)
          .then(resp => {
            this.training_date = resp.data;
          })
          .catch(err => {
            this.$toastr.error(err);
          });
      }
    },
    saveChange() {
      this.$emit("loading", true);
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/training-dates`;

      this.$http
        .customRequest({
          method: this.$route.params.id !== undefined ? "put" : "post",
          url:
            this.$route.params.id !== undefined
              ? `${url}/${this.$route.params.id}`
              : url,
          data: this.training_date
        })
        .then(() => {
          // console.log(resp);
          this.$toastr.success("Zrobione!");
          this.$emit("loading", false);
        });
    }
  }
};
</script>
