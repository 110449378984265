<template>
  <div class="row">
    <div class="col-12">
      <ibox
        :title="this.$t('nav.training-dates-list')"
        :collapseId="'collapse-2'"
      >
        <template slot="content" slot-scope="{ setLoading }">
          <TrainingDatesForm @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import TrainingDatesForm from "@/components/Courses/TrainingDatesForm.vue";

export default {
  name: "TrainingDatesDetails",
  components: {
    Ibox,
    TrainingDatesForm
  },
  data() {
    return {
      excel: true
    };
  }
};
</script>
